import Img500 from '@/assets/img/500_img@2x.png';
import HeaderLogo from '@/assets/img/logo_active@2x.png';
import Button from '@/components/Button';
import Footer from '@/components/Footer';
import Image from '@/components/Image';
import { Tit } from '@/components/Titles';
import GlobalStyle from '@/GlobalStyle';
import { breakpoint } from '@/helpers/BreakpointHelper';
import { Link, navigate, PageProps } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  height: 100vh;
  min-height: 1000px;
  position: relative;

  footer {
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 0;
  }

  ${breakpoint(`tablet`)} {
    height: auto;
    min-height: 0;
    footer {
      display: none;
    }
  }
`;
const Header = styled.header`
  padding: 50px 0;

  ${breakpoint(`tablet`)} {
    padding: 30px 0;
  }
`;

const Logo = styled.h1`
  display: block;
  width: 110px;
  margin: 0 auto;

  ${breakpoint(`tablet`)} {
    width: 95px;
  }
`;

const LogoLink = styled((props) => <Link {...props} />)`
  display: block;
  width: 100%;
  padding-top: 54.55%;
  background: url(${HeaderLogo}) no-repeat center center;
  background-size: contain;
  position: relative;
`;

const Content = styled.div`
  max-width: 1160px;
  padding: 0 20px;
  margin: 0 auto;
  height: calc(100vh - 360px);
  min-height: 600px;
  display: block;
  display: flex;
  align-items: center;

  dt,
  dd {
    padding: 30px 50px;
  }

  h2 {
    font-size: 180px;
    line-height: 1;
    display: block;
    color: #1cabe2;
    margin-bottom: 35px;
  }
  p {
    margin-top: 16px;
    margin-bottom: 36px;
  }

  dd {
    img {
      max-width: 100%;
    }
  }

  ${breakpoint(1160)} {
    h2 {
      font-size: 15.52vw;
    }

    dt,
    dd {
      padding: 0 4.31vw;
    }
  }
  ${breakpoint(`tablet`)} {
    height: auto;
    min-height: 0;

    padding-bottom: 50px;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 320px;

    dt,
    dd {
      width: 100%;
      padding: 0;
    }

    dd {
      margin-bottom: 40px;
      text-align: center;
      img {
        max-width: 240px;
      }
    }

    h2 {
      display: none;
    }
    ${Tit} {
      font-size: 20px;
    }
    p {
      margin-top: 8px;
    }
  }
`;

const NotFound: React.FC<PageProps> = () => (
  <>
    <GlobalStyle />
    <Wrapper>
      <Header>
        <Logo>
          <LogoLink to="/">
            <span className="for-a11y">유니세프</span>
          </LogoLink>
        </Logo>
      </Header>
      <Content>
        <dt>
          <h2>500</h2>
          <Tit size="s2" color="sky">
            페이지에 일시적인 오류가 <br />
            발생하였습니다
          </Tit>
          <p>양해 부탁 드리며, 빠른 시일 내에 해결하겠습니다.</p>
          <Button full outline onClick={() => navigate(`/`)}>
            메인 화면 바로가기
          </Button>
        </dt>
        <dd>
          <Image pcSrc={Img500} mobileSrc={Img500} alt="" />
        </dd>
      </Content>
      <Footer />
    </Wrapper>
  </>
);

export default NotFound;
